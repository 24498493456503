import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/agent/workspace/s_provision-documentation_master/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Networking Helper`}</h1>
    <p>{`Provision Portal is an online webapplication that relies, of course, on network connectivity within a customers network and out to AWS.`}</p>
    <h1>{`Customer Firewalls and Networking`}</h1>
    <p>{`Customers must use Fully Qualified Domain Names (FQDN) for configuration. Some customers may try to use IP addresses but that will not work for AWS.
The resources we use in AWS are “hidden” behind load balancers and friendly names.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      